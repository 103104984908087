<template>
    <div id="companythankyoupaid">
        <div class="main-page-wrapper p0">
            <!-- ===================================================
				Loading Transition
			==================================================== -->
            <section>
                <div id="preloader">
					<div id="ctn-preloader" class="ctn-preloader">
						<div class="animation-preloader">
							<div class="text-center">
								<img class="spinner" src="../../../assets/image/ingomu-preloader.gif" alt="" />
							</div>
							<!-- <div class="spinner"></div> -->
							<div class="txt-loading">
								<span data-text-preloader="i" class="letters-loading">
									i
								</span>
								<span data-text-preloader="n" class="letters-loading">
									n
								</span>
								<span data-text-preloader="g" class="letters-loading-green">
									g
								</span>
								<span data-text-preloader="o" class="letters-loading-green">
									o
								</span>
								<span data-text-preloader="m" class="letters-loading">
									m
								</span>
								<span data-text-preloader="u" class="letters-loading">
									u
								</span>
							</div>
						</div>
					</div>
				</div>
            </section>


            <!-- 
			=============================================
				Theme Main Menu
			============================================== 
			-->
            <SiteHeader></SiteHeader>

            <!--
			=====================================================
				Feature Blog One
			=====================================================
			-->
            <div class="blog-page-bg">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 feature-blog-one width-lg blog-details-post-v1">
                            <div class="post-meta pb35">
                                <h3 class="title">Welcome to Ingomu</h3>
                                <p class="terms-content__text">You are now ready to access all benefits included with
                                    your Ingomu subscription. </p>
                                <div class="terms-content__box">
                                    <div class="mark-text">Download the Ingomu app</div>

                                    <div class="d-sm-flex align-items-center justify-content-left button-group pt35">
                                        <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank"
                                            class="d-flex align-items-center ios-button">
                                            <img src="../../../assets/v2/images/icon/apple.svg" alt="" class="icon">
                                            <div>
                                                <span>Download on the</span>
                                                <strong>App store</strong>
                                            </div>
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu"
                                            target="_blank" class="d-flex align-items-center windows-button">
                                            <img src="../../../assets/v2/images/icon/playstore.svg" alt="" class="icon">
                                            <div>
                                                <span>Get it on</span>
                                                <strong>Google play</strong>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <p>If you had a private subscription - one you paid for yourself
                                    - please go to the Apple App Store or Google Play Store on your device and cancel your
                                    subscription plan no less than 24 hours prior to your renewal date to avoid being
                                    charged.</p>
                                <div class="terms-content__box">
                                    <div class="mark-text">Expiration</div>
                                    <p v-if="expireTerm == 'annual'">Your corporate subscription
                                        expires on <span class="bold">{{ annualExpireDate() }}</span>.</p>
                                    <p v-if="expireTerm == 'semi'">Your corporate subscription expires
                                        on <span class="bold">{{ semiExpireDate() }}</span>.</p>
                                </div>
                                <div class="terms-content__box">
                                    <div class="mark-text">Support</div>
                                    <p>We don't believe in chatbots, they never seem to
                                        quite get you the answers you are looking for. We invite you to email us at
                                        contact@ingomu.com and a real person will respond to you, generally the same
                                        business day, Monday to Friday 9:00 a.m. to 6:00 p.m. Mountain Time. Be as
                                        detailed as possible when contacting us so we may send you the best response.
                                        <br /><br />Add ingomu.com to your trusted sender list to ensure you are
                                        receiving our reply. Check your spam or promotional folder should you not
                                        receive our reply in your inbox. Email gremlins may have directed our response
                                        there. A detailed help menu is available on the app by tapping the Help icon in the
                                        footer on Android, and the Account, then Help on the iOS app.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!--
			=====================================================
				Footer Style Seven
			=====================================================
			-->
            <SiteFooter></SiteFooter>

            <!-- Modal Contact Form One -->
            <div class="modal fade modal-contact-popup-one" id="contactModal" tabindex="-1" role="dialog"
                aria-labelledby="contactModalTitle" aria-hidden="true">
                <ContactUs @clicked="closeModal"></ContactUs>
            </div>


        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import postscribe from "postscribe";
import SiteHeader from "@/components/SiteHeader";
import SiteFooter from "@/components/SiteFooter";
import ContactUs from "@/components/ContactUs";
import formateDateMixin from "@/mixins/formatDate";

export default {
    name: 'companythankyoupaid',
    metaInfo() {
        return {
            title: "Thank you for joining Ingomu",
        }
    },
    components: {
        SiteHeader,
        SiteFooter,
        ContactUs
    },
    mixins: [formateDateMixin],
    data() {
        return {
            corpImage: "https://dktoyr513tjgs.cloudfront.net/image/ingomu_logo-2.png",
            description: "",
            name: "",
            expireTerm: "annual"

        }
    },
    mounted() {
        this.expireTerm = this.$route.params.term
        this.$store.dispatch("getCorpDetails", { slug: this.$route.params.slug })
        postscribe(
            "#companythankyoupaid",
            `<script src="https://dktoyr513tjgs.cloudfront.net/v2/vendor/mega-menu/assets/js/custom.js"><\/script>`
        );
        postscribe(
            "#companythankyoupaid",
            `<script src="https://dktoyr513tjgs.cloudfront.net/v2/js/theme.js?v=3"><\/script>`
        );
    },
    computed: {
        ...mapGetters(["corpDetails"]),
    },
    watch: {
        corpDetails(newValue) {
            if (newValue.length <= 0) {
                this.$router.push({ path: '/404' })
            } else {
                if (newValue[0].corpimage != "" && newValue[0].corpimage != null) {
                    this.corpImage = newValue[0].corpimage;
                }
                this.name = newValue[0].name
                this.description = newValue[0].description
            }
        }
    }

}
</script>
<style scoped>
.pb35 {
    padding-bottom: 35px !important;
}

.pt35 {
    padding-top: 35px !important;
}

.blog-page-bg {
    background: linear-gradient(45deg, #46c346, #3b5998);
}

.button-group a {
    width: 200px;
    height: 60px;
    padding: 0 5px 0 25px;
    margin: 10px 12px;
    background: #F0F0F0;
    border-radius: 6px;
    color: #000;
    text-align: left;
    transition: all 0.3s ease-in-out;
}

.button-group a:hover {
    transform: translateY(-5px);
    box-shadow: -5px 10px 30px rgba(0, 0, 0, 0.1);
}

.button-group a .icon {
    margin-right: 15px;
}

.button-group a span {
    font-size: 11px;
    color: #737373;
    display: block;
    margin-bottom: -11px;
    margin-top: -5px;
}

.button-group a strong {
    font-weight: 500;
    font-size: 18px;
    display: block;
}

.button-group a.ios-button {
    background: #303030;
    color: #fff;
}

.button-group a.ios-button span {
    color: rgba(255, 255, 255, 0.7);
}</style>